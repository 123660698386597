import React from "react"
import SEO from "../components/seo"
import Header from "../components/header"
import Footer from "../components/Footer"
import FixedInfoButton from "../components/no-use-component/FixedInfoButton"
import images from "../components/images"
import { Helmet } from "react-helmet"
import FormResponseModel from "../components/form-response-model";

export default ({data}) => (
    <div>
        <SEO title="Home"/>
        <Header> </Header>
        <Helmet>
            <html lang="en" />
        <title>Cosmetic Registration in India </title>
    <meta name="description" content="Cosmoally keeps you updated with the latest information required for the cosmetics registration in India. This app is the fastest way to learn about the cosmetics registration process, to identify the category for your products, to know more about compliance and regulation, and to get direct regulatory assistance. Cosmoally is India’s first app that gives complete information about how to launch cosmetic products in the Indian market."/>
    <meta property="og:url" content="https://cosmoally.com/"/>
    <meta property="og:title" content="Cosmetic Registration in India"/>
    <meta property="og:description" content="Cosmoally keeps you updated with the latest information required for the cosmetics registration in India. This app is the fastest way to learn about the cosmetics registration process, to identify the category for your products, to know more about compliance and regulation, and to get direct regulatory assistance. Cosmoally is India’s first app that gives complete information about how to launch cosmetic products in the Indian market."/>
    <meta property="og:image" content="https://cosmoally.com/wp-content/themes/cosmoally/images//mockup_2.png"/>



  <link rel="icon" href="https://cosmoally.com/wp-content/uploads/2020/04/favicon1.jpg" sizes="32x32"/>
<link rel="icon" href="https://cosmoally.com/wp-content/uploads/2020/04/favicon1.jpg" sizes="192x192"/>
<link rel="apple-touch-icon" href="https://cosmoally.com/wp-content/uploads/2020/04/favicon1.jpg"/>
<meta name="msapplication-TileImage" content="https://cosmoally.com/wp-content/uploads/2020/04/favicon1.jpg"/>

    {/* <!-- Required meta tags --> */}
    <meta charset="utf-8"/>
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no"/>

    {/* <!--Css Font awesome-->*/}
    <link href="https://fonts.googleapis.com/css?family=Muli:200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i&amp;display=swap" rel="stylesheet"/>
    <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css"/>
    {/* <!-- CSS Front Template -->*/}

	{/* <!-- Global site tag (gtag.js) - Google Analytics -->*/}
<script type="text/javascript" async="" src="https://www.google-analytics.com/analytics.js"></script>
<script async="" src="https://www.googletagmanager.com/gtag/js?id=UA-158707108-1"></script>

	<script type="application/ld+json">
    {
        `{"@context": "https://schema.org",
        "@type": "FAQPage",
        "mainEntity": [
            {
                "@type": "Question",
                "name": "Do I need to pay for the App?",
                "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "No, CosmoAlly mobile App is free to download and can be used by individual Mobile users."
                }
            },
            {
                "@type": "Question",
                "name": "How can I install this App?",
                "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "CosmoAlly mobile App for iOS that is optimized for iPhone devices can be installed from the Apple App Store.CosmoAlly mobile App for Android can be installed from Google Play Store"
                }
            },
            {
                "@type": "Question",
                "name": "How can I use this App?",
                "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "CosmoAlly provides you a tour or tutorial which can be easily accessed within the App."
                }
            },
            {
                "@type": "Question",
                "name": "How many cosmetic products can be categorized in this App?",
                "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "CosmoAlly App can run for five number of products. In case of further query, the user can contact the technical team of the App."
                }
            },
            {
                "@type": "Question",
                "name": "What is CosmoAlly Mobile App?",
                "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "CosmoAlly Mobile App is the first regulatory App which provides easy and instant guidance for users intending to import cosmetic products in India. The app can guide you in categorizing and registering your cosmetic product in India."
                }
            },
            {
                "@type": "Question",
                "name": "How can I get the answers to the question not given in the App?",
                "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "To get the answer to your question, you can directly contact our team atcosmoally@cliniexperts.com"
                }
            },
            {
                "@type": "Question",
                "name": "How can I know more about the App?",
                "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "To know more about the App you can go to Cliniexperts website www.cliniexperts.com or contact us at cosmoally@cliniexperts.com."
                }
            },
            {
                "@type": "Question",
                "name": "How CosmoAlly Mobile App can help you through in importing cosmetics in India?",
                "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "Cosmetic Registration is an important step for the whole import process. It can guide its users with the latest information required for cosmetic registration in India and in determining the product category and calculating the Government fees for the same to import cosmetic products in India."
                }
            },
            {
                "@type": "Question",
                "name": "How many knowledge-based questions can be accessed?",
                "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "Ten knowledge-based questions can be accessed."
                }
            },
            {
                "@type": "Question",
                "name": "Why is Product Categorization important for cosmetic Registration in India?",
                "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "As government charges for cosmetic registration application are based on the number of categories and variants, hence product categorization is important."
                }
            }
        ]
    }`}
    </script>

	<script type="application/ld+json">
{
    `{"@context": "http://schema.org",
    "@type": "WebSite",
    "name": "Cosmoally",
    "alternateName": "",
    "url": "https://cosmoally.com/",
    "potentialAction": [
        {
            "@type": "SearchAction",
            "target": "https://cosmoally.com/{search_term_string}",
            "query-input": "required name=search_term_string"
        }
    ]
}`}
</script>

    <script type="application/ld+json">
{
    `{"@context": "http://schema.org",
    "@type": "BreadcrumbList",
    "itemListElement": [
        {
            "@type": "ListItem",
            "position": 1,
            "item": {
                "@id": "https://cosmoally.com/",
                "name": "Cosmoally"
            }
        }
    ]
}`}
</script>

    <script type="application/ld+json">
{
    `{
    "@context": "http://schema.org",
    "@type": "Organization",
    "name": "Cosmoally",
    "url": "https://cosmoally.com/",
    "logo": "https://cosmoally.com/wp-content/themes/cosmoally/images/logo.png",
    "contactPoint": [
        {
            "@type": "ContactPoint",
            "telephone": "+91-7672005050",
            "contactType": "sales",
            "email": "contact@cliniexperts.com",
            "availableLanguage": [
                "English"
            ]
        }
    ],
    "sameAs": [
        "https://www.facebook.com/Cosmoally-100258648284729/",
        "https://www.instagram.com/cosmoally/"
    ]
}`}
</script>

        </Helmet>
        <aside class="container px-0 mx-auto">
            {/*<!--cosmoally your gateway-->*/}
            <div class="container-fluid clini-theme-top-gap" id="cosmoally-about">
                <div class="row">
                    <div class="col-12 col-lg-10 offset-lg-1 text-center">
                        <h3 className="clini-theme-heading color-normal mb-3">Cosmoally - Your gateway to Indian cosmetics
                            industry!</h3>
                        <p class="clini-theme-subheading color-dark mb-4">
                            Cosmoally keeps you updated with the latest information required for the cosmetics
                            registration in India.
                            This app is the fastest way to learn about the cosmetics registration process, to identify
                            the category for your products,
                            to know more about compliance and regulation, and to get direct regulatory assistance.
                            Cosmoally is India’s first app that gives complete information about how to launch cosmetic
                            products in the Indian market.
                        </p>
                    </div>
                </div>
            </div>

            {/*<!--cosmoally features-->*/}
            <div class="container-fluid cosmoally-features clini-theme-top-gap" id="cosmoally-features">
                <div class="row">
                    <div class="col-md-5 col-sm-12 col-xs-12">
                        <div>
                            <h3 class="cosmoally-theme-subheading color-blue mb-0 font-bold">FEATURES</h3>
                            <h3 class="cosmoally-theme-heading color-normal  font-800">What we offer</h3>
                            <p class="cosmoally-theme-text color-dark">
                                The Cosmoally app will give you step-by-step instructions on how to categorize and
                                register your cosmetics products.
                                In addition, you will find information on regulations for your product compliance.
                            </p>
                            <p class="cosmoally-theme-text color-dark">
                                <b>Categorization:</b> It will facilitate categorizing your product and the calculation
                                for its Government fees.
                                Upload up to 5 product images for free to identify the right category
                            </p>
                        </div>

                    </div>
                    <div class="col-md-7  col-sm-12 col-xs-12">
                        <img class="img-fluid" src={images.MockUp2} alt="Cosmoally App"/>
                    </div>
                </div>
            </div>

            <div class="container-fluid clini-theme-big-top-gap" id="cosmoally-install-app">
                <div class="row">
                    <div class="col-12">
                        <div class="text-center line-both-side">
                            <div className="content-container">

                                <a href="https://play.google.com/store/apps/details?id=io.cosmoally.app" target="_blank"
                                   className="app-download-link">
                                    <img src={images.GooglePlay} className="img-fluid app-store-images"
                                         alt="Cosmoally App - Get It On Google Play"/>
                                </a>
                                &nbsp;&nbsp;
                                <a href="https://apps.apple.com/in/app/cosmoally/id1482558525"
                                   className="app-download-link"
                                   target="_blank">
                                    <img src={images.AppleApp} className="img-fluid app-store-images"
                                         alt="Cosmoally App - Download on the App Store"/>
                                </a>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            {/*<!--Cosmetic App-->*/}
            <div class="container-fluid clini-theme-top-gap">
                <div class="row">
                    <div class="col-12 text-center">
                        <h3 class="clini-theme-heading color-normal">
                            Cosmoally - A
                            <img src={images.CELogo}
                                 alt="Cosmoally app by CliniExperts, Your Regulatory Partner"/>
                            Cosmetic App
                        </h3>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 text-center">
                        <p class="clini-theme-subheading color-dark mb-2">
                            The Cosmoally app has been developed by CliniExperts. CliniExperts offers end-to-end
                            regulatory solutions to pharma,
                            food, cosmetic, medical devices, nutraceuticals, biological & vaccines industries.
                            CliniExperts work towards the mission of paving the way for a safer,
                            healthier society through facilitating the availability of quality products in the market.
                            For more information about CliniExperts and its services visit:
                        </p>
                    </div>
                    <div class="col-12 text-center">
                        <a href="http://www.cliniexperts.com/">www.cliniexperts.com</a>
                    </div>
                </div>
            </div>

            {/*<!--Testimonial-->*/}
            <div class="container-fluid cosmoally-testimonial clini-theme-big-top-gap" id="cosmoally-testimonials">
                <div class="row">
                    <div class="col-12 text-center">
                        <h3 class="cosmoally-theme-subheading color-blue mb-0"><b>TESTIMONIAL</b></h3>
                        <h3 class="cosmoally-theme-heading color-normal mb-0"><b>Special Quotes From</b></h3>
                        <h3 class="cosmoally-theme-heading color-normal mb-0"><b>Client For Us!</b></h3>
                    </div>
                </div>
                <div class="row text-center trusted_by_logo_section pt-5">

                    <div class="testimonial_box_container pt-5">
                        <div class="testimonial_box active box-shadow">
                            <div class="testimonial-box-quote-image">
                                <img src={images.CosQuote} class="img-fluid"/>
                            </div>
                            <p class="cosmoally-theme-text color-dark text-left mt-5 mb-4">
                                The Cosmoally App is really wonderful and very informative.
                                Everything works really well. The App is really easy to navigate and understand because
                                of the simple language used.
                                I can't imagine working with any other Cosmetic App in India, which gives one-on-one
                                customer service.
                            </p>
                            <div class="container-fluid">
                                <div class="row">
                                    <div class="col-3 pl-0">
                                        <img
                                            src={images.UserImage}
                                            class="img-fluid rounded-circle"/>
                                    </div>
                                    <div class="col-9 my-auto">
                                        <div class="text-left">
                                            <h4 class="cosmoally-theme-subheading color-normal mb-0">Krishna
                                                Kurkute</h4>
                                            <h5 class="cosmoally-theme-text color-normal">Owner, Keauty Beauty</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="testimonial_box box-shadow">
                            <div class="testimonial-box-quote-image">
                                <img src={images.CosQuote} class="img-fluid"/>
                            </div>
                            <p class="cosmoally-theme-text color-dark text-left mt-5 mb-4">
                                CliniExperts has made the work so simple. This App, Cosmoally is so fast and easy to
                                work with.
                                It’s a one-stop solution for any query related to label and compliance for Cosmetics
                                regsitration. Thanks, guys!!
                            </p>
                            <div class="container-fluid">
                                <div class="row">
                                    <div class="col-3 pl-0">
                                        <img
                                            src={images.UserImage}
                                            class="img-fluid rounded-circle"/>
                                    </div>
                                    <div class="col-9 my-auto">
                                        <div class="text-left">
                                            <h4 class="cosmoally-theme-subheading color-normal mb-0">Kshiteesh
                                                Deshmukh</h4>
                                            <h5 class="cosmoally-theme-text color-normal">Founder, Peakstate Lifestyle
                                                LLP</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="testimonial_box box-shadow">
                            <div class="testimonial-box-quote-image">
                                <img src={images.CosQuote} class="img-fluid"/>
                            </div>
                            <p class="cosmoally-theme-text color-dark text-left mt-5 mb-4">
                                Cosmoally app provides the kind of service that will take the business to a different
                                level as all the processes related to cosmetics are explained well.
                                The app is engaging and the look is appealing.
                                The efficient technology in the app is bringing the best results in our business.
                                We would highly recommend the app!
                            </p>
                            <div class="container-fluid">
                                <div class="row">
                                    <div class="col-3 pl-0">
                                        <img
                                            src={images.UserImage}
                                            class="img-fluid rounded-circle"/>
                                    </div>
                                    <div class="col-9 my-auto">
                                        <div class="text-left">
                                            <h4 class="cosmoally-theme-subheading color-normal mb-0">Nitin</h4>
                                            <h5 class="cosmoally-theme-text color-normal">Founder, Spa Asia</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="testimonial_box box-shadow">
                            <div class="testimonial-box-quote-image">
                                <img src={images.CosQuote} class="img-fluid"/>
                            </div>
                            <p class="cosmoally-theme-text color-dark text-left mt-5 mb-4">
                                Cosmoally is an engaging app and provides excellent support to business initiatives in
                                the cosmetics industry.
                                The tutorials and knowledge box will assist you during the application process,
                                as there is a solution in there for all your registration- related queries. Thank you
                                CliniExperts!
                            </p>
                            <div class="container-fluid">
                                <div class="row">
                                    <div class="col-3 pl-0">
                                        <img
                                            src={images.UserImage}
                                            class="img-fluid rounded-circle" alt="Poornima"/>
                                    </div>
                                    <div class="col-9 my-auto">
                                        <div class="text-left">
                                            <h4 class="cosmoally-theme-subheading color-normal mb-0">Poornima</h4>
                                            <h5 class="cosmoally-theme-text color-normal">Mirabee Cosmetics</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="row">
                    <div class="col-12 d-flex justify-content-center">
                        <div class="testimonial_dot_container pt-4 d-flex mb-4">
                            <div class="testimonial_dot ml-2 active" data-slider="previous">
                                <img src={images.ArrLeft} alt="" class="img-fluid"/>
                            </div>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <div class="testimonial_dot ml-2" data-slider="next">
                                <img src={images.ArrRight} alt="" class="img-fluid"/>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row clini-theme-big-top-gap">
                    <div class="col-12 text-center">
                        <h3 class="cosmoally-theme-subheading color-blue mb-0"><b>FAQS</b></h3>
                        <h3 class="cosmoally-theme-heading color-normal mb-0"><b>Frequently Asked</b></h3>
                        <h3 class="cosmoally-theme-heading color-normal mb-0"><b>Questions</b></h3>
                    </div>
                </div>
            </div>

            {/*<!--Frequently Asked Questions-->*/}
            <div class="container-fluid frequently_asked_questions_section" id="cosmoally-faqs">
                <div class="row  clini-theme-top-gap">
                    <div class="col-md-6 col-sm-12 col-xs-12">
                        <div>

                            <div class="accordion" id="accordionExample">
                                <div class="card accordion_item mb-4 bdr-12">
                                    <div class="card-header-section" id="headingOne">
                                        <h2 class="mb-0">

                                            <div class="container-fluid accordion_heading collapsed"
                                                 data-toggle="collapse" data-target="#collapseOne" aria-expanded="false"
                                                 aria-controls="collapseOne">
                                                <div class="row">
                                                    <div class="col-11">
                                                        <p class="cosmoally-theme-text color-dark font-bold text-left py-md-3 px-0 mb-0">
                                                            Do I need to pay for the App?
                                                        </p>
                                                    </div>
                                                    <div class="col-1 my-auto text-right  pl-0 pl-sm-3">
                                                        <div><img src={images.ChevronDown} class="plus-sign"/>
                                                            <img src={images.ChevronUp} class="minus-sign"/></div>
                                                    </div>
                                                </div>
                                            </div>


                                        </h2>
                                    </div>

                                    <div id="collapseOne" class="collapse" aria-labelledby="headingOne"
                                         data-parent="#accordionExample">
                                        <div class="card-body">
                                            <p class="cosmoally-theme-text color-normal">No, CosmoAlly mobile App is
                                                free to download and can be used by individual Mobile users.</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="card accordion_item mb-4 bdr-12">
                                    <div class="card-header-section" id="headingTwo">
                                        <h2 class="mb-0">

                                            <div class="container-fluid accordion_heading collapsed"
                                                 data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false"
                                                 aria-controls="collapseTwo">
                                                <div class="row">
                                                    <div class="col-11">
                                                        <p class="cosmoally-theme-text color-dark font-bold text-left py-md-3 px-0 mb-0">
                                                            How can I install this App?
                                                        </p>
                                                    </div>
                                                    <div class="col-1 my-auto text-right  pl-0 pl-sm-3">
                                                        <div><img src={images.ChevronDown} class="plus-sign"/>
                                                            <img src={images.ChevronUp} class="minus-sign"/></div>
                                                    </div>
                                                </div>
                                            </div>


                                        </h2>
                                    </div>
                                    <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo"
                                         data-parent="#accordionExample">
                                        <div class="card-body">
                                            <ul>
                                                <li class="cosmoally-theme-text color-normal">CosmoAlly mobile App for
                                                    iOS that is optimized for iPhone devices can be installed from the
                                                    Apple App Store.
                                                </li>
                                                <li class="cosmoally-theme-text color-normal">CosmoAlly mobile App for
                                                    Android&nbsp;can be installed from&nbsp;<u><a
                                                        href="https://play.google.com/store/apps/details?id=b1.sales.mobile.android">Google
                                                        Play</a></u> Store
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div class="card accordion_item mb-4 bdr-12">
                                    <div class="card-header-section" id="headingThree">
                                        <h2 class="mb-0">

                                            <div class="container-fluid accordion_heading collapsed"
                                                 data-toggle="collapse" data-target="#collapseThree"
                                                 aria-expanded="false" aria-controls="collapseThree">
                                                <div class="row">
                                                    <div class="col-11">
                                                        <p class="cosmoally-theme-text color-dark font-bold text-left py-md-3 px-0 mb-0">
                                                            How can I use this App?
                                                        </p>
                                                    </div>
                                                    <div class="col-1 my-auto text-right  pl-0 pl-sm-3">
                                                        <div><img src={images.ChevronDown} class="plus-sign"/>
                                                            <img src={images.ChevronUp} class="minus-sign"/></div>
                                                    </div>
                                                </div>
                                            </div>

                                        </h2>
                                    </div>
                                    <div id="collapseThree" class="collapse" aria-labelledby="headingThree"
                                         data-parent="#accordionExample">
                                        <div class="card-body">
                                            <p class="cosmoally-theme-text color-normal">CosmoAlly provides you a tour
                                                or tutorial which can be easily accessed within the App.</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="card accordion_item mb-4 bdr-12">
                                    <div class="card-header-section" id="headingFour">
                                        <h2 class="mb-0">

                                            <div class="container-fluid accordion_heading collapsed"
                                                 data-toggle="collapse" data-target="#collapseFour"
                                                 aria-expanded="false" aria-controls="collapseFour">
                                                <div class="row">
                                                    <div class="col-11">
                                                        <p class="cosmoally-theme-text color-dark font-bold text-left py-md-3 px-0 mb-0">
                                                            How many cosmetic products can be categorized in this App?
                                                        </p>
                                                    </div>
                                                    <div class="col-1 my-auto text-right  pl-0 pl-sm-3">
                                                        <div><img src={images.ChevronDown} class="plus-sign"/>
                                                            <img src={images.ChevronUp} class="minus-sign"/></div>
                                                    </div>
                                                </div>
                                            </div>

                                        </h2>
                                    </div>
                                    <div id="collapseFour" class="collapse" aria-labelledby="headingFour"
                                         data-parent="#accordionExample">
                                        <div class="card-body">
                                            <p class="cosmoally-theme-text color-normal">
                                                CosmoAlly App can run for five number of products. In case of further
                                                query, the user can contact the technical team of the App.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="card accordion_item mb-4 bdr-12">
                                    <div class="card-header-section" id="headingFive">
                                        <h2 class="mb-0">

                                            <div class="container-fluid accordion_heading collapsed"
                                                 data-toggle="collapse" data-target="#collapseFive"
                                                 aria-expanded="false" aria-controls="collapseFive">
                                                <div class="row">
                                                    <div class="col-11">
                                                        <p class="cosmoally-theme-text color-dark font-bold text-left py-md-3 px-0 mb-0">
                                                            What is CosmoAlly Mobile App?
                                                        </p>
                                                    </div>
                                                    <div class="col-1 my-auto text-right  pl-0 pl-sm-3">
                                                        <div><img src={images.ChevronDown} class="plus-sign"/>
                                                            <img src={images.ChevronUp} class="minus-sign"/></div>
                                                    </div>
                                                </div>
                                            </div>

                                        </h2>
                                    </div>
                                    <div id="collapseFive" class="collapse" aria-labelledby="headingFive"
                                         data-parent="#accordionExample">
                                        <div class="card-body">
                                            <p class="cosmoally-theme-text color-normal">
                                                CosmoAlly Mobile App is the first regulatory App which provides easy and
                                                instant guidance for users intending to import cosmetic products in
                                                India. The app can guide you in categorizing and registering your
                                                cosmetic product in India.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="col-md-6 col-sm-12 col-xs-12">
                        <div>

                            <div class="accordion" id="accordionExample2">
                                <div class="card accordion_item mb-4 bdr-12">
                                    <div class="card-header-section" id="headingOne2">
                                        <h2 class="mb-0">

                                            <div class="container-fluid accordion_heading collapsed"
                                                 data-toggle="collapse" data-target="#collapseOne2"
                                                 aria-expanded="false" aria-controls="collapseOne2">
                                                <div class="row">
                                                    <div class="col-11">
                                                        <p class="cosmoally-theme-text color-dark font-bold text-left py-md-3 px-0 mb-0">
                                                            How can I get the answers to the question not given in the
                                                            App?
                                                        </p>
                                                    </div>
                                                    <div class="col-1 my-auto text-right  pl-0 pl-sm-3">
                                                        <div><img src={images.ChevronDown} class="plus-sign"/>
                                                            <img src={images.ChevronUp} class="minus-sign"/></div>
                                                    </div>
                                                </div>
                                            </div>


                                        </h2>
                                    </div>

                                    <div id="collapseOne2" class="collapse" aria-labelledby="headingOne2"
                                         data-parent="#accordionExample2">
                                        <div class="card-body">
                                            <p class="cosmoally-theme-text color-normal">To get the answer to your
                                                question, you can directly contact our team at <strong><a
                                                    href="mailto:cosmoally@cliniexperts.com">cosmoally@cliniexperts.com</a>.</strong>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="card accordion_item mb-4 bdr-12">
                                    <div class="card-header-section" id="headingTwo2">
                                        <h2 class="mb-0">

                                            <div class="container-fluid accordion_heading collapsed"
                                                 data-toggle="collapse" data-target="#collapseTwo2"
                                                 aria-expanded="false" aria-controls="collapseTwo2">
                                                <div class="row">
                                                    <div class="col-11">
                                                        <p class="cosmoally-theme-text color-dark font-bold text-left py-md-3 px-0 mb-0">
                                                            How can I know more about the App?
                                                        </p>
                                                    </div>
                                                    <div class="col-1 my-auto text-right  pl-0 pl-sm-3">
                                                        <div><img src={images.ChevronDown} class="plus-sign"/>
                                                            <img src={images.ChevronUp} class="minus-sign"/></div>
                                                    </div>
                                                </div>
                                            </div>


                                        </h2>
                                    </div>
                                    <div id="collapseTwo2" class="collapse" aria-labelledby="headingTwo2"
                                         data-parent="#accordionExample2">
                                        <div class="card-body">
                                            <p class="cosmoally-theme-text color-normal">To know more about the App you
                                                can go to Cliniexperts website <a
                                                    href="http://www.cliniexperts.com">www.cliniexperts.com</a> or
                                                contact us at <strong><a
                                                    href="mailto:cosmoally@cliniexperts.com">cosmoally@cliniexperts.com</a></strong><strong>.</strong>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="card accordion_item mb-4 bdr-12">
                                    <div class="card-header-section" id="headingThree2">
                                        <h2 class="mb-0">

                                            <div class="container-fluid accordion_heading collapsed"
                                                 data-toggle="collapse" data-target="#collapseThree2"
                                                 aria-expanded="false" aria-controls="collapseThree2">
                                                <div class="row">
                                                    <div class="col-11">
                                                        <p class="cosmoally-theme-text color-dark font-bold text-left py-md-3 px-0 mb-0">
                                                            How CosmoAlly Mobile App can help you through in importing
                                                            cosmetics in India?
                                                        </p>
                                                    </div>
                                                    <div class="col-1 my-auto text-right pl-0 pl-sm-3">
                                                        <div><img src={images.ChevronDown} class="plus-sign"/>
                                                            <img src={images.ChevronUp} class="minus-sign"/></div>
                                                    </div>
                                                </div>
                                            </div>

                                        </h2>
                                    </div>
                                    <div id="collapseThree2" class="collapse" aria-labelledby="headingThree2"
                                         data-parent="#accordionExample2">
                                        <div class="card-body">
                                            <p class="cosmoally-theme-text color-normal">
                                                Cosmetic Registration is an important step for the whole import process.
                                                It can guide its users with the latest information required for cosmetic
                                                registration in India and in determining the product category and
                                                calculating the Government fees for the same to import cosmetic products
                                                in India.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="card accordion_item mb-4 bdr-12">
                                    <div class="card-header-section" id="headingFour2">
                                        <h2 class="mb-0">

                                            <div class="container-fluid accordion_heading collapsed"
                                                 data-toggle="collapse" data-target="#collapseFour2"
                                                 aria-expanded="false" aria-controls="collapseFour2">
                                                <div class="row">
                                                    <div class="col-11">
                                                        <p class="cosmoally-theme-text color-dark font-bold text-left py-md-3 px-0 mb-0">
                                                            How many knowledge-based questions can be accessed?
                                                        </p>
                                                    </div>
                                                    <div class="col-1 my-auto text-right  pl-0 pl-sm-3">
                                                        <div><img src={images.ChevronDown} class="plus-sign"/>
                                                            <img src={images.ChevronUp} class="minus-sign"/></div>
                                                    </div>
                                                </div>
                                            </div>

                                        </h2>
                                    </div>
                                    <div id="collapseFour2" class="collapse" aria-labelledby="headingFour2"
                                         data-parent="#accordionExample2">
                                        <div class="card-body">
                                            <p class="cosmoally-theme-text color-normal">
                                                Ten knowledge-based questions can be accessed.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="card accordion_item mb-4 bdr-12">
                                    <div class="card-header-section" id="headingFive2">
                                        <h2 class="mb-0">

                                            <div class="container-fluid accordion_heading collapsed"
                                                 data-toggle="collapse" data-target="#collapseFive2"
                                                 aria-expanded="false" aria-controls="collapseFive2">
                                                <div class="row">
                                                    <div class="col-11">
                                                        <p class="cosmoally-theme-text color-dark font-bold text-left py-md-3 px-0 mb-0">
                                                            Why is Product Categorization important for cosmetic
                                                            Registration in India?
                                                        </p>
                                                    </div>
                                                    <div class="col-1 my-auto text-right  pl-0 pl-sm-3">
                                                        <div><img src={images.ChevronDown} class="plus-sign"/>
                                                            <img src={images.ChevronUp} class="minus-sign"/></div>
                                                    </div>
                                                </div>
                                            </div>

                                        </h2>
                                    </div>
                                    <div id="collapseFive2" class="collapse" aria-labelledby="headingFive2"
                                         data-parent="#accordionExample2">
                                        <div class="card-body">
                                            <p class="cosmoally-theme-text color-normal">
                                                As government charges for cosmetic registration application are based on
                                                the number of categories and variants, hence product categorization is
                                                important.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

        </aside>
        <Footer/>
        <FixedInfoButton/>
        <FormResponseModel />
    </div>
)

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`

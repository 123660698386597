import React, { Component } from "react"
import images from "./images";

class HomeBanner extends React.Component {

    render() {
        return (
            <div className="container mx-auto">
                <div className="row">
                    <div className="col-md-5 col-sm-12 col-xs-12 mt-5">
                        <div>
                            <h1 className="cosmoally-theme-heading-big font-bold color-white"><b className={'main-heading-bold'}>YOUR ULTIMATE GUIDE TO
                                COSMETIC REGISTRATION IN INDIA</b></h1>
                            <br/>
                            <div>
                                <div className="container-fluid px-0">
                                    <div className="row">

                                        <div className="col-12">
                                            <i className="fa fa-check" style={{color:"#0ab661"}} aria-hidden="true"> </i>
                                            <span className="mb-3 px-2 color-white">Cosmetic Registration Process</span>
                                        </div>
                                        <div className="col-12">
                                            <i className="fa fa-check"  style={{color:"#0ab661"}} aria-hidden="true"> </i>
                                            <span className="mb-3 px-2 color-white">Product Registration</span>
                                        </div>
                                        <div className="col-12">
                                            <i className="fa fa-check" style={{color:"#0ab661"}} aria-hidden="true"> </i>
                                            <span className="mb-3 px-2 color-white">Government Fees Calculation</span>
                                        </div>
                                        <div className="col-12">
                                            <i className="fa fa-check"  style={{color:"#0ab661"}} aria-hidden="true"> </i>
                                            <span className="mb-3 px-2 color-white">Knowledge Box</span>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <br/>
                            <br/>
                            <div>
                                <button class="btn btn-pill bg-color-pink text-white" data-toggle="modal" data-target="#exampleModal"><i className="fa fa-paper-plane-o" style={{fontSize:'22px'}} aria-hidden="true"> </i>&nbsp;&nbsp;<span class="fsm-theme-very-small-text pb-2 pr-2" style={{fontSize:'12px'}} >ENQUIRE NOW</span></button>
                            </div>
                        </div>

                    </div>
                    <div className="col-md-7  col-sm-12 col-xs-12">
                        <img className="img-fluid" src={images.MobMockUp} alt="Cosmoally App on Mobile Device "/>
                    </div>
                </div>
            </div>
        )
    }
}
export default HomeBanner

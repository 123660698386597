import React, { Component } from 'react';
import MyForm from "./MyForm"

class FixedInfoButton extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <div>
        {/*<!--Fixed inform button -->*/}
        <div class="home_fixed_info_button" data-toggle="modal" data-target="#exampleModal">
        <button class="btn fixed_button bg-color-pink color-white">Contact Us</button>
    </div>
        {/*<!--end fixed inform button -->*/}
        <MyForm/>
      </div>
    )
  }
}
export default FixedInfoButton

import React, {createRef, useState, Component} from "react"
import ReCAPTCHA from "react-google-recaptcha"
import $ from "jquery"
import emailjs from "emailjs-com"
import { SERVICE_ID,TEMPLATE_ID,USER_ID } from "../GLOBAL"

const MyForm = props => {

    const myForm = React.useRef();

    const [error, setError] = useState('d-none');
    const [cap, setCap] = useState(false);
    const [loading, setLoading] = useState('d-none');

    const key = "6LdX-PgUAAAAACTvpgVhDBrLFvU0pquLWXhEmh1x"
    const recaptchaRef = createRef();

    const onChangeCaptcha = value => {
        setError('d-none');
        setCap(true);
    }


    const handleSubmit = event => {
        setLoading('d-inline-block');
        event.preventDefault();

        if (!cap) {
            setError('d-block');
            setLoading('d-none');
            return false
        }

        setTimeout(function () {
            $("#exampleModal").modal('hide');
        }, 1500)

                  emailjs.init(USER_ID)
                  emailjs
                      .send(SERVICE_ID, TEMPLATE_ID, {
                          my_html: '<ul>\n' +
                              '    <li><strong>From: </strong>'+myForm.current.name.value+'</li>\n' +
                              '    <li><strong>Email Id: </strong>'+myForm.current.email.value+'</li>\n' +
                              '    <li><strong>Phone No.: </strong>'+myForm.current.phone_number.value+'</li>\n' +
                              '    <li><strong>Company Name: </strong>'+myForm.current.company_name.value+'</li>\n' +
                              '    <li><strong>Comment: </strong><br/>'+myForm.current.message.value+'</li>\n' +
                              '</ul>'
                      })
                      .then(
                          function(response) {

                              let m = $("#formReponseModel");
                              m.modal('show');
                              m.find(".success-class").show()
                              m.find(".error-class").hide()
                              setLoading('d-none');
                              myForm.current.reset()
                              setTimeout(function (){m.modal('hide');}, 5000)
                          },
                          function(error) {

                              let m = $("#formReponseModel");
                              m.modal('show');
                              m.find(".error-class").show()
                              m.find(".success-class").hide()
                              m.find(".error-class").text("Error: "+error.text)
                              setLoading('d-none');
                              setTimeout(function (){m.modal('hide');}, 5000)
                          }
                      )
    }

    return (
        <div>
            <div
                className="modal fade"
                id="exampleModal"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content text-left">
                        <div className="modal-body border-0">
                            <form action="!#" method="POST" ref={myForm} onSubmit={handleSubmit}
                                  className="border border-light p-4 border-0">
                                <div className="text-center ">
                                    <p className="h4 mb-4">Contact Us</p>
                                </div>


                                <div className="form-row">
                                    <div className="form-group col-md-6">
                                        <input type="text" id="defaultRegisterFormFirstName" className="form-control" placeholder="Enter Your Name*" required name="name"/>
                                    </div>
                                    <div className="form-group col-md-6">
                                        <input type="email" id="defaultRegisterFormEmail" className="form-control mb-4" placeholder="Enter your  email*" required name="email"/>
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="form-group col-md-6">
                                        <input type="number" id="defaultRegisterFormPassword" className="form-control mb-4" placeholder="Enter your phone number" required name="phone_number"/>
                                    </div>
                                    <div className="form-group col-md-6">
                                        <input type="text" id="defaultRegisterFormLastName" className="form-control" placeholder="Enter your company name" required name="company_name"/>
                                    </div>
                                </div>


                                <div className="form-row">
                                    <div className="form-group col-md-12">
                                        <textarea className="form-control" name="message" id="inputComment" placeholder="Comment." rows="4" cols="3"> </textarea>
                                    </div>
                                </div>

                                <div className="form-row">
                                    <div className="form-group col-md-8">
                                        <ReCAPTCHA sitekey={key} ref={recaptchaRef} onChange={onChangeCaptcha}/>
                                    </div>
                                    <div className="form-group col-md-4 text-right">
                                        <button className="btn btn-info my-4 btn-block" type="submit">
                                            Submit
                                            <div className={"spinner-border ml-2 " + loading} role="status"
                                                 style={{height: '20px', width: '20px'}}>
                                                <span className="sr-only">Loading...</span>
                                            </div>
                                        </button>
                                    </div>
                                </div>


                                <div className={"form-row " + error}>
                                    <div className="form-group col-md-12">
                                        <p className="fsm-theme-small-text text-danger">Error : &nbsp; Please complete
                                            captcha first</p>
                                    </div>
                                </div>

                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default MyForm
